import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../../general/belowFoldCards"
import CTA from "../../general/cta"
import FAQList from "../../general/faqsList"
import NetworkAntennaIcon from "../../../../../assets/img/network-antennas.inline.svg"
import AirtimeIcon from "../../../../../assets/img/airtime-icon.inline.svg"
import BarGraphIcon from "../../../../../assets/img/bar-graph-fill.inline.svg"
import ImageTextLeft from "../../general/imageTextLeft"
import ImageTextRight from "../../general/imageTextRight"
import EntryIllustration from "../../../../../assets/img/airtime-entry-illustration.inline.svg"
import ConfirmTransactionIllustration from "../../../../../assets/img/kuda-transaction-confirm.inline.svg"
import TopupIllustration from "../../../../../assets/img/transaction-notifications.inline.svg"
import ChooseNetworkIllustration from "../../../../../assets/img/kuda-choose-network.inline.svg"
import { scrollToElement } from "../../../../utility/utils" 
import BadgesEntryFold from "../../general/badgesEntryFold"
import MoreForYouComponent from "../../general/more-for-you"

import BorrowIcon from '../../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'
import TransferIcon from '../../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaInternetIcon from '../../../../../assets/img/more-for-you/kuda-internet-icon.inline.svg'

const entryContent = {
  title: "Recharge your phone easily on the Kuda app",
  subtitle: "Buy Airtel, Glo, MTN and 9Mobile airtime and internet data any time.",
  illustration: <EntryIllustration/>,
}

const kudaTopFeatures = [
  {
    icon: <NetworkAntennaIcon />,
    text: "Buy airtime from any Nigerian mobile network.",
  },
  {
    icon: <AirtimeIcon />,
    text: "Top up airtime on your phone in a few seconds.",
  },
  {
    icon: <BarGraphIcon />,
    text: "Subscribe to your favourite mobile data plan easily.",
  },
]

const chooseNetwork = {
  payTitle: "Find all Nigerian mobile networks on Kuda",
  subtitle:
    "Buy Airtel, Glo, MTN and 9Mobile airtime and internet data directly from your account.",
  url: "/joinKuda/",
  name: "Join Kuda",
  payIllustration: <ChooseNetworkIllustration />,
}

const questions = [
  {
    heading: "How do I buy airtime on the Kuda app?",
    list: (
      <span className="flex flex-column">
        <span>To buy airtime, follow the steps below:</span>
        <span className="pt-3 career-message-margin-bottom ">
        <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
          <li className="mb-3">Sign in to your Kuda app, then tap Pay.</li>
          <li className="mb-3">Tap Buy Airtime.</li>
          <li className="mb-3">Type in the amount of airtime you’d like to buy, choose the phone network, then type in the phone number and tap Next.</li>
          <li>Complete your airtime recharge with your transaction ID, fingerprint or Face ID.</li>
        </ol>
      </span>
      </span>),
    index: 1
  },
  {
    heading: "How do I buy internet data on the Kuda app?",
    list: (
      <span className="flex flex-column">
        <span>To buy internet data, follow the steps below:</span>
        <span className="pt-3 career-message-margin-bottom ">
        <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
          <li className="mb-3">Sign in to your Kuda app, then tap Pay.</li>
          <li className="mb-3">Tap Pay A Bill.</li>
          <li className="mb-3">Tap Internet.</li>
          <li className="mb-3">Choose your mobile network or internet service provider.</li>
          <li className="mb-3">Choose your internet subscription package (data plan).</li>
          <li className="mb-3">Type in your phone number or customer ID, then tap Next.</li>
          <li>Confirm your data purchase with your transaction PIN, fingerprint or Face ID.</li>
        </ol>
      </span>
      </span>),
    index: 2
  },
  {
    heading: (<div className="question-title-max">
      Can I recharge a Nigerian phone number with the Kuda app when I’m abroad?
    </div>),
    list: (
      <span className="flex flex-column"><span>Yes, you can send airtime or internet data to any Nigerian mobile phone number from your Kuda app when you’re abroad.
      </span>
      </span>),
    index: 3
  },
  {
    heading: <div className="question-title-max">
    Which mobile networks can I buy airtime and data from on the Kuda app?
  </div>,
    list: (
      <span className="flex flex-column"><span>You can buy airtime and internet data from all Nigerian mobile networks - Airtel, Glo, MTN and 9Mobile - on the Kuda app. You can also buy internet data from Smile and Spectranet on the app.</span> </span>),
    index: 4
  },
  {
    heading: "When can I use the airtime or data that I buy on the Kuda app?",
    list: (
      <span className="flex flex-column">
        <p>You’ll get your airtime recharge in a few seconds after you complete your purchase on the Kuda app, and you can use the airtime immediately.</p>
        <p className="pt-3"> Internet data subscriptions sometimes take up to a few minutes to become active, but you’ll be able to use your data as soon as your subscription is active. </p>
      </span>),
    index: 5
  }
]
const airtimeTopup = {
  payTitle: "Get your airtime top-up in a few seconds.",
  subtitle:
    " You’ll get your airtime or data soon after you complete your purchase on the app.",
  url: "/joinKuda/",
  name: "Join Kuda",
  payIllustration: <TopupIllustration />,
}

const airtimeShare = {
  payTitle: "Send airtime to your friends and family.",
  subtitle:
    "Recharge any Nigerian phone number from your Kuda app even when you’re abroad.",
  url: "https://help.kuda.com/support/solutions/articles/73000560691-buy-airtime",
  name: "Learn How To Buy Airtime",
  payIllustration: <ConfirmTransactionIllustration />,
}

const moreForYou = [
  {
      icon: <KudaInternetIcon />,
      title: "Internet",
      subText: `Buy internet data from major Nigerian internet service providers easily on your Kuda app.`,
      linkTo: `/en-ng/bill-payments/internet/`
  },
  {
    icon: <TransferIcon />,
    title: "Transfer & Spend",
    subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
    linkTo: `/en-ng/spend/`
  },
  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: `Get instant loans up to ₦150,000 in the Kuda loan app easily in Nigeria without paperwork.`,
    linkTo: `/en-ng/personal-loan/`
},

]
const AirtimePage = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={chooseNetwork.payTitle}
        subtitle={chooseNetwork.subtitle}
        illustration={chooseNetwork.payIllustration}
        name={chooseNetwork.name}
        url={chooseNetwork.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={airtimeTopup.payTitle}
        subtitle={airtimeTopup.subtitle}
        illustration={airtimeTopup.payIllustration}
        name={airtimeTopup.name}
        url={airtimeTopup.url}
        isExternal={true}
      />
      <ImageTextRight
        title={airtimeShare.payTitle}
        subtitle={airtimeShare.subtitle}
        illustration={airtimeShare.payIllustration}
        name={airtimeShare.name}
        url={airtimeShare.url}
        isExternal={true}
      />
      <FAQList title={"Airtime & Internet Data FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou}/>
      <CTA />
    </Fragment>
  )
}

export default AirtimePage
